import React, { useState } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import SEO from "../components/seo"
import Footer from "../components/footer"
import MobileMenu from "../components/mobile-menu"
import DesktopMenu from "../components/desktop-menu"
import RightArrow from "../images/icons/right-arrow.svg"
import CloseIcon from "../images/icons/close-icon.svg"

import "./index.scss"
import "../components/layout.scss"

const IndexPage = ({ data }) => {
  const [bannerHidden, setBannerHidden] = useState(false)
  const [showFullBanner, setShowFullBanner] = useState(false)
  return (
    <>
      <SEO title="Leonie Freudenberger" />
      <div className="logo-container"></div>
      <MobileMenu />
      <DesktopMenu />
      <div className="background fade-in">
        <Img
          fluid={
            data.contentfulHomepage.heroImage.localFile.childImageSharp.fluid
          }
          fadeIn={true}
        />
      </div>
      {/* Banner that shows when configured in Netlify  */}
      {bannerHidden === false && data.contentfulHomepage.bannerVisible && (
        <div
          className="banner-wrapper"
          data-sal="fade"
          data-sal-delay="300"
          data-sal-duration="1000"
        >
          <div className="banner">
            <div className="inline">
              <button
                className="close-icon"
                onClick={() => setBannerHidden(true)}
                onKeyDown={() => setBannerHidden(true)}
              >
                <img src={CloseIcon} alt="Close"></img>
              </button>
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    data.contentfulHomepage.bannerSummary.childMarkdownRemark
                      .html,
                }}
              ></span>
              {!showFullBanner && (
                <span
                  role="presentation"
                  className="more"
                  onClick={() => setShowFullBanner(true)}
                  onKeyDown={() => setShowFullBanner(true)}
                >
                  <img src={RightArrow} alt="→"></img>Mehr&nbsp;Infos
                </span>
              )}
            </div>
            {showFullBanner && (
              <div
                className="full-banner"
                dangerouslySetInnerHTML={{
                  __html:
                    data.contentfulHomepage.bannerFullText.childMarkdownRemark
                      .html,
                }}
              ></div>
            )}
          </div>
        </div>
      )}

      <div className="introduction-container">
        <div
          className="introduction"
          data-sal="fade"
          data-sal-delay="600"
          data-sal-duration="1000"
        >
          <h1>
            {" "}
            <span>{data.contentfulHomepage.heroHeader}</span>
          </h1>
          <h2>{data.contentfulHomepage.heroBody}</h2>
          <div className="buttons">
            {data.contentfulHomepage.heroButtons.map(button => (
              <a
                href={button.link}
                className={`button ${button.appearance ? "light" : "dark"}`}
              >
                <img
                  src={button.icon.localFile.publicURL}
                  alt={button.title}
                ></img>
                <span>{button.title}</span>
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className="footer-homepage">
        <Footer />
      </div>
    </>
  )
}

export const query = graphql`
  {
    contentfulHomepage(id: { eq: "4391cc10-a551-5b94-8e58-5cdc5e937532" }) {
      bannerVisible
      bannerFullText {
        childMarkdownRemark {
          html
        }
      }
      bannerSummary {
        childMarkdownRemark {
          html
        }
      }

      heroBody
      heroButtons {
        title
        link
        icon {
          localFile {
            publicURL
          }
        }

        appearance
      }
      heroHeader
      heroImage {
        localFile {
          childImageSharp {
            fluid(maxWidth: 3000) {
              aspectRatio
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default IndexPage
